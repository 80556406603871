import React, { useEffect } from "react";
import fbTrack from "../../components/_helpers";

function Playlist(props) {
  useEffect(() => {
    // https://open.spotify.com/playlist/7vnuuglFyRoSq8rvteszqI
    if (typeof window !== "undefined") {
      let pathname = props.params.playlistRedirect;
      // check if number of characters exceeds the limit
      if (pathname && pathname?.length < 30) {
        window.location.href = `https://open.spotify.com/playlist/${pathname}`;
      } else {
        window.location.href = "https://open.spotify.com/playlist/7vnuuglFyRoSq8rvteszqI";
      }
      fbTrack("trackCustom", "truevined-spotify-playlist-visit-redirect");
    }
  }, []);

  return <div></div>;
}

export default Playlist;
